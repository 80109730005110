import Engine, { CardStateArray as ICardStateArray, CardStateAddress, CardStateEnum, GuessFlags } from "./index";
import deck, { Player, Card } from "../deck";
import { mod, next } from "../util";

export interface CardStateArray extends ICardStateArray {
	set(address: CardStateAddress, state: CardStateEnum, rule: Rule): void;
}

export abstract class Rule {
	abstract check(stateArray: CardStateArray): void;
	abstract cardList(): CardStateAddress[];
	score(card: CardStateAddress) { return 0; }
}

abstract class RuleSet {
	private rules: Rule[] = [];
	protected addRule(c: Rule) {
		this.rules.push(c);
	};
	check(stateArray: CardStateArray) {
		this.rules.forEach(v => v.check(stateArray));
	}
	cardList = () => this.rules.reduce((acc, cur) => acc.concat(cur.cardList()), <CardStateAddress[]>[])

	score = (card: CardStateAddress) => this.rules.reduce((acc, cur) => acc + cur.score(card), 0)
}

export class SetRule extends Rule {
	private readonly cards: CardStateAddress[];
	private readonly state: CardStateEnum
	constructor(cards: CardStateAddress[], state: CardStateEnum) {
		super();
		this.cards = cards;
		this.state = state;
	}
	check(stateArray: CardStateArray) {
		this.cards.forEach(card => stateArray.set(card, this.state, this));
	}
	cardList() {
		return this.cards.slice();
	}
}

export class CardMutexRule extends RuleSet {
	constructor(engine: Engine) {
		super();
		engine.cardStatesAddresses.forEach(v => this.addRule(new Conditional(v, CardStateEnum.Has, CardStateEnum.Not)));
	}
}

export class FinalAnswerRule extends RuleSet {
	constructor(engine: Engine) {
		super();
		deck.categories.forEach(category => {
			let c = category.cards.map((value) => engine.cardStatesAddresses[value.id][engine.players.length]);
			this.addRule(new Conditional(c, CardStateEnum.Has, CardStateEnum.Not));
		});
	}
}

export class CardCountingRule extends RuleSet {
	constructor(engine: Engine, dealer: Player) {
		super();

		let cardsInHands = deck.cards.length - deck.categories.length;
		let baseCards = Math.floor(cardsInHands / engine.players.length);
		let extra = mod(cardsInHands, engine.players.length);
		engine.players.forEach(player => {
			let states = deck.cards.map(card => engine.cardStatesAddresses[card.id][player.id]);
			let a = baseCards;
			if (mod(player.id - dealer.id - 1, engine.players.length) < extra) a++;
			this.addRule(new Conditional(states, CardStateEnum.Has, CardStateEnum.Not, a));
		});
	}
}

export class GuessRule extends RuleSet {
	constructor(engine: Engine, guesser: Player, guess: Card[],
		flags: GuessFlags, disprover?: Player) {
		super();
		if (flags & GuessFlags.Accuse) {
			if (flags & GuessFlags.Disproved) {
				this.addRule(new Conditional(guess.map(value =>
					engine.cardStatesAddresses[value.id][guesser.id]), CardStateEnum.Not));	//Asumption
				this.addRule(new Conditional(guess.map(value =>
					engine.cardStatesAddresses[value.id][engine.players.length]),
					CardStateEnum.Has));
			} else {
				this.addRule(new SetRule(guess.map(value =>
					engine.cardStatesAddresses[value.id][engine.players.length]), CardStateEnum.Has));
			}
		} else {
			if (flags & GuessFlags.Disproved) {
				if (!disprover) throw new Error("Disprover can not be null");
				let not: CardStateAddress[] = [];
				guess.forEach(value => {
					for (let curr = next(guesser, engine.players);
						curr != disprover; curr = next(curr, engine.players)) {
						not.push(engine.cardStatesAddresses[value.id][curr.id]);
					}
				});
				this.addRule(new SetRule(not, CardStateEnum.Not));
				this.addRule(new Conditional(guess.map(value =>
					engine.cardStatesAddresses[value.id][disprover.id]),
					CardStateEnum.Has));
			} else {
				let not: CardStateAddress[] = [];
				guess.forEach(value => {
					for (let curr = next(guesser, engine.players);
						curr != guesser; curr = next(curr, engine.players)) {
						not.push(engine.cardStatesAddresses[value.id][curr.id]);
					}
				});
				this.addRule(new SetRule(not, CardStateEnum.Not));
			}
		}
	}
}

export class Conditional extends Rule {
	private readonly cards: CardStateAddress[];
	private readonly amounts: number[]
	constructor(states: CardStateAddress[], one: CardStateEnum,
		many?: CardStateEnum, amount?: number);
	constructor(states: CardStateAddress[], amounts: number[]);
	constructor(states: CardStateAddress[], one: number[] | CardStateEnum,
		many: CardStateEnum = CardStateEnum.Unkown, amount: number = 1) {
		super();
		this.cards = states;
		if (one instanceof Array) {
			this.amounts = one;
		} else {
			this.amounts = [0, 0, 0];
			this.amounts[one] = amount;
			this.amounts[many] = states.length - amount;
		}

		if (this.amounts.reduce((a, b) => a + b) != this.cards.length)
			throw new Error("Length mismatch");
	}
	check(stateArray: CardStateArray) {
		let amounts = this.amounts.slice();

		let unknowns = this.cards.filter(card => {
			let state = stateArray.get(card);

			if (state == CardStateEnum.Unkown)
				return true;

			if (amounts[state] > 0)
				amounts[state]--;
			else if (amounts[CardStateEnum.Unkown] > 0)
				amounts[CardStateEnum.Unkown]--;
			else
				throw new Error("Condtradiction during decrementing");

			return false;
		});

		if (unknowns.length != amounts.reduce((a, b) => a + b))
			throw new Error("Contradiction during final count");
		amounts.forEach((a, i) => {
			if (a == unknowns.length)
				unknowns.forEach(u => stateArray.set(u, i, this));
		});
	}
	cardList() {
		return this.cards.slice();
	}
}
