interface IDisplayable {
	readonly name: string;
	readonly id: number;
	readonly img?: string;
}

interface ICard extends IDisplayable {
	readonly category: number;
	readonly categoryid: number;
}
class Card implements ICard {
	readonly name: string;
	readonly id: number;
	readonly category: number;
	readonly categoryid: number;
	readonly img?: string;
	constructor(name: string, id: number, category: number, categoryid: number, img?: string) {
		this.name = name;
		this.id = id;
		this.category = category;
		this.categoryid = categoryid;
		this.img = img;
	}
}

interface ICategory extends IDisplayable { cards: Card[] };

class Player implements IDisplayable {
	readonly name: string;
	readonly id: number;
	readonly img?: string;
	constructor(name: string, id: number, img?: string) {
		this.name = name;
		this.id = id;
		this.img = img;
	}
}

class Deck {
	players: Player[] = [];
	technicalPlayers: Player[] = [];
	cards: Card[] = [];
	categories: ICategory[];
	constructor(categories: string[]) {
		this.categories = [];
		for (let i = 0; i < categories.length; i++) {
			this.categories[i] = { id: i, name: categories[i], cards: [] }
		}
	}
	addPlayer(name: string, img?: string, technical = false) {
		const id = technical ? this.technicalPlayers.length : this.players.length;
		let player = new Player(name, id, img);
		if (technical)
			this.technicalPlayers[id] = player;
		else
			this.players[id] = player;
	}
	addCard(name: string, category: number, img?: string) {
		const id = this.cards.length;
		const categoryid = this.categories[category].cards.length;
		let card = new Card(name, id, category, categoryid, img);
		this.cards[id] = card;
		this.categories[category].cards[categoryid] = card;
	}
}



export { ICard as Card, ICategory as Category, IDisplayable, Player }

let deck = new Deck(["Suspects", "Weapons", "Rooms"]);

deck.addPlayer("Miss. Scarlet");
deck.addPlayer("Col. Mustard");
deck.addPlayer("Mrs. White");
deck.addPlayer("Mr. Green");
deck.addPlayer("Mrs. Peacock");
deck.addPlayer("Prof. Plum");

deck.addPlayer("Mr. Body", undefined, true);

deck.addCard("Col. Mustard", 0);
deck.addCard("Prof. Plum", 0);
deck.addCard("Mr. Green", 0);
deck.addCard("Mrs. Peacock", 0);
deck.addCard("Miss. Scarlet", 0);
deck.addCard("Mrs. White", 0);

deck.addCard("Knife", 1);
deck.addCard("Candlestick", 1);
deck.addCard("Revolver", 1);
deck.addCard("Rope", 1);
deck.addCard("Lead Pipe", 1);
deck.addCard("Wrench", 1);

deck.addCard("Hall", 2);
deck.addCard("Lounge", 2);
deck.addCard("Dining Room", 2);
deck.addCard("Kitchen", 2);
deck.addCard("Ball Room", 2);
deck.addCard("Conservatory", 2);
deck.addCard("Billiard Room", 2);
deck.addCard("Library", 2);
deck.addCard("Study", 2);

export default deck;
