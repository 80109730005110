import * as React from 'react'
import * as ReactDOM from 'react-dom';

import { ready } from "../util";
import Root from './Root';

function init() {
	let root = document.createElement("div");
	document.body.appendChild(root);

	ReactDOM.render(<Root />, root);
}

ready.then(init);
