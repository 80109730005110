import * as React from "react";

import Engine, { CardStateEnum } from "../engine";
import deck, { Player, Card, IDisplayable } from "../deck";
import { SetRule } from "../engine/rule";

export default class PreGame extends React.Component<{ onEngineReady: (engine: Engine) => any },
	{ players?: Player[], me?: Player, dealer?: Player, cards?: Card[] }>{

	constructor(props: Readonly<{ onEngineReady: (engine: Engine) => any; }>) {
		super(props);
		this.state = {};
	}

	componentDidUpdate() {
		if (this.state.players && this.state.me && this.state.dealer && this.state.cards) {
			let engine = new Engine(this.state.players, this.state.me, this.state.dealer);
			(window as any).engine = engine;
			engine.addRule(new SetRule(this.state.cards.map(card =>
				engine.cardStatesAddresses[card.id][engine.me.id]), CardStateEnum.Has));
			this.props.onEngineReady(engine);
		}
	}

	render() {
		if (!this.state.players)
			return <MultipleSelector options={deck.players} prompt="Which players are playing?"
				onSelected={value => this.setState({ players: value })} init />;
		if (!this.state.me)
			return <Selector options={this.state.players} prompt="Who are you?"
				onSelected={value => this.setState({ me: value })} />
		if (!this.state.dealer)
			return <Selector options={this.state.players} prompt="Who is the dealer?"
				onSelected={value => this.setState({ dealer: value })} />
		if (!this.state.cards)
			return <MultipleSelector options={deck.cards} prompt="What cards do you have?"
				onSelected={value => this.setState({ cards: value })} />

		return null;
	}
}

class MultipleSelector<T extends IDisplayable> extends React.Component<{
	prompt?: string, options: T[], onSelected: (value: T[]) => any, init?: boolean
}, { selected: boolean[] }> {
	constructor(props: Readonly<{ prompt?: string; options: T[]; onSelected: (value: T[]) => any, init?: boolean }>) {
		super(props);
		this.state = { selected: [] }
		this.props.options.forEach(opt => this.state.selected[opt.id] = !!this.props.init);
	}
	handleClick = (i: number) => this.setState(oldState => {
		let s = oldState.selected.slice();
		s[i] = !s[i];
		return { selected: s };
	});

	submit = () => this.props.onSelected(this.props.options.filter(opt =>
		this.state.selected[opt.id]));

	render() {
		let options = this.props.options.map(opt =>
			<Selectable value={opt} key={opt.id} className={this.state.selected[opt.id] ? "" : "unselected"}
				onClick={this.handleClick.bind(this, opt.id)} />);

		return <div className="pre-sel">
			{this.props.prompt && (<h2 className="prompt">{this.props.prompt}</h2>)}
			<div className="cont">{options}</div>
			<a className="bottom" onClick={this.submit}>Continue</a>
		</div >;
	}
}

class Selector<T extends IDisplayable> extends React.Component<{
	prompt?: string, options: T[], onSelected: (value: T) => any
}> {
	submit = (i: number) => {
		let option = this.props.options.find(opt => opt.id == i);
		if (!option) throw new Error("Selected option is not an option... what?")
		this.props.onSelected(option);
	}

	render() {
		let options = this.props.options.map(opt =>
			<Selectable value={opt} key={opt.id}
				onClick={this.submit.bind(this, opt.id)} />);

		return <div className="pre-sel">
			{this.props.prompt && (<h2 className="prompt">{this.props.prompt}</h2>)}
			<div className="cont">{options}</div>
		</div >;
	}
}

class Selectable extends React.Component<{
	value: IDisplayable, className?: string,
	onClick: (value: IDisplayable) => any
}> {
	render() {
		let className = "opt " + this.props.className || "";
		return <a className={className} onClick={() => this.props.onClick(this.props.value)}>
			<Displayable displayable={this.props.value} /></a>;
	}
}

class Displayable extends React.Component<{ displayable: IDisplayable }>{
	render() {
		return <div>
			{this.props.displayable.img && (<img src={this.props.displayable.img} />)}
			<span>{this.props.displayable.name}</span>
		</div>
	}
}
