import * as React from "react";

import Engine from "../engine";

import Notebook from "./Notebook";
import Guesser from "./Guess";
import PreGame from "./PreGame";

export default class Root extends React.Component<{}, { engine?: Engine }> {

	constructor(props: Readonly<{ engine: Engine; }>) {
		super(props);
		this.state = {};
	}

	engineReady = (engine: Engine) => this.setState({ engine: engine })

	render() {
		if (this.state.engine)
			return <InGame engine={this.state.engine} />;
		else
			return <PreGame onEngineReady={this.engineReady} />;
	}
}

class InGame extends React.Component<{ engine: Engine }> {
	render() {
		return <div><Notebook engine={this.props.engine} />
			<Guesser engine={this.props.engine} /></div>
	}
}
