import * as React from 'react'

import deck, { Category, Card } from "../deck";
import Engine, { CardStateEnum, CardStateAddress, CardStateArray } from '../engine';
import { SetRule } from '../engine/rule';

export default class Notebook extends React.Component<{ engine: Engine }, { cardStateArray?: CardStateArray }> {

	constructor(props: Readonly<{ engine: Engine; }>) {
		super(props);
		this.state = {};
	}

	componentDidMount() { this.props.engine.onNewState(this.updateListener); }

	componentWillUnmount() { this.props.engine.removeOnNewState(this.updateListener); }

	updateListener = (cardStateArray: CardStateArray) => this.setState({ cardStateArray: cardStateArray });

	render() {
		if (this.state.cardStateArray != undefined) {
			let categories = deck.categories.map(category =>
				<NotebookCategory engine={this.props.engine} category={category} key={category.id}
					cardStateArray={this.state.cardStateArray as CardStateArray} />);
			return <table>{categories}</table>
		}
		return null;
	}
}

function NotebookCategory(props: { engine: Engine, category: Category, cardStateArray: CardStateArray }) {
	let cardRows = props.category.cards.map(card =>
		<NotebookCardRow engine={props.engine} card={card} key={card.id}
			cardStateArray={props.cardStateArray} />);
	return (
		<tbody>
			<tr>
				<th colSpan={props.engine.technicalPlayers.length + 1}>
					{props.category.name}
				</th>
			</tr>
			{cardRows}
		</tbody>
	);
}

function NotebookCardRow(props: { engine: Engine, card: Card, cardStateArray: CardStateArray }) {
	let cards = props.engine.cardStatesAddresses[props.card.id].map((state, i) =>
		<NotebookCardState engine={props.engine} card={state} key={i}
			cardStateArray={props.cardStateArray} />
	);
	return (
		<tr>
			<td>{props.card.id} {props.card.name}</td>
			{cards}
		</tr>

	);

}

class NotebookCardState extends React.Component<{ engine: Engine, card: CardStateAddress, cardStateArray: CardStateArray }> {
	handleClick(secondary: boolean, ev: React.MouseEvent) {
		ev.preventDefault();
		if (this.props.cardStateArray.get(this.props.card) == CardStateEnum.Unkown)
			this.props.engine.addRule(new SetRule([this.props.card], secondary ? CardStateEnum.Not : CardStateEnum.Has));
	}
	render() {
		let state = this.props.cardStateArray.get(this.props.card);
		let colour = "white";
		if (state == CardStateEnum.Has) colour = "green";
		if (state == CardStateEnum.Not) colour = "red";
		return <td style={{ background: colour, width: "1em" }}
			onClick={this.handleClick.bind(this, false)}
			onContextMenu={this.handleClick.bind(this, true)}></td >;
	}
}
