import deck, { Card, Player } from "../deck";
import { next, mod } from "../util";
import { Rule, CardStateArray as RuleCardStateArray, CardCountingRule, FinalAnswerRule, CardMutexRule, GuessRule } from "./rule";

enum CardStateEnum { Unkown, Has, Not };
class CardStateAddress {
	private rules: Rule[] = [];
	readonly card: Card;
	readonly player: Player;
	constructor(engine: Engine, card: Card, player: Player) {
		this.card = card;
		this.player = player;
	}
	addRule(c: Rule) {
		if (this.rules.indexOf(c) >= 0)
			throw new Error("Rule already added to card");
		this.rules.push(c);
	}
};

class CardState {
	readonly address: CardStateAddress;
	rule?: Rule;
	state: CardStateEnum = CardStateEnum.Unkown;
	constructor(address: CardStateAddress) {
		this.address = address;
	}
}

interface ICardStateArray {
	get(address: CardStateAddress): CardStateEnum;
}

class CardStateArray implements RuleCardStateArray {
	private engine: Engine
	private data: CardState[][];
	needsUpdate: boolean = true;
	constructor(engine: Engine) {
		this.engine = engine;
		this.data = this.engine.cardStatesAddresses.map(c => c.map(p => new CardState(p)));
	}
	private rawGet = (address: CardStateAddress) => this.data[address.card.id][address.player.id];
	get = (address: CardStateAddress) => this.rawGet(address).state;
	set(address: CardStateAddress, state: CardStateEnum, rule: Rule) {
		const s = this.rawGet(address);
		if (s.state == state) return;
		if (s.state != CardStateEnum.Unkown)
			throw new Error("State was already set by " + s.rule);
		if (state == CardStateEnum.Unkown) return;

		this.needsUpdate = true;
		s.state = state;
		s.rule = rule;
	}
}

export { CardStateEnum, CardStateAddress, ICardStateArray as CardStateArray }

enum GuessFlags {
	None = 0,
	Accuse = 1 << 0,
	Disproved = 1 << 1
}

export { GuessFlags }

export default class Engine {
	readonly players: Player[];
	readonly technicalPlayers: Player[];
	readonly me: Player;
	readonly cardStatesAddresses: CardStateAddress[][];
	private rules: Rule[] = [];
	constructor(players: Player[], me: Player, dealer: Player = me) {
		this.players = players.map((oldPlayer, i) => {
			const newPlayer = new Player(oldPlayer.name, i, oldPlayer.img);
			if (me == oldPlayer) me = newPlayer;
			if (dealer == oldPlayer) dealer = newPlayer;
			return newPlayer;
		});
		this.me = me;

		this.technicalPlayers = this.players.slice().concat(deck.technicalPlayers
			.map((oldPlayer, i) =>
				new Player(oldPlayer.name, i + this.players.length, oldPlayer.img)));

		this.cardStatesAddresses = new Array(deck.cards.length);
		for (let i = 0; i < deck.cards.length; i++) {
			this.cardStatesAddresses[i] = new Array(this.technicalPlayers.length);
			for (let j = 0; j < this.cardStatesAddresses[i].length; j++) {
				this.cardStatesAddresses[i][j] =
					new CardStateAddress(this, deck.cards[i], this.technicalPlayers[j]);
			}
		}

		this.addRule(new CardMutexRule(this));
		this.addRule(new FinalAnswerRule(this));
		this.addRule(new CardCountingRule(this, dealer));

		this.update();
	}

	addRule(c: Rule) {
		c.cardList().forEach(card => card.addRule(c));
		this.rules.push(c);
		this.update();
	}

	makeGuess(guesser: Player, guess: Card[],
		flags: GuessFlags, disprover?: Player) {
		this.addRule(new GuessRule(this, guesser, guess, flags, disprover));
	}

	update(restart = false) {
		let stateArray = new CardStateArray(this);

		while (stateArray.needsUpdate) {
			stateArray.needsUpdate = false;
			this.rules.forEach(c => c.check(stateArray));
		}

		this.listeners.forEach(l => l(stateArray));
	};

	private listeners: ((state: ICardStateArray) => any)[] = [];
	onNewState(f: (state: ICardStateArray) => any) {
		let i = this.listeners.indexOf(f);
		if (i < 0) this.listeners.push(f);
		this.update();
	};
	removeOnNewState(f: (state: ICardStateArray) => any) {
		let i = this.listeners.indexOf(f);
		if (i >= 0) this.listeners.splice(i, 1);
	};
}
