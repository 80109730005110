let ready = new Promise(function (resolve) {
	if (document.readyState === "loading")
		document.addEventListener("DOMContentLoaded", resolve);
	else resolve();
});

const mod = (a: number, n: number) => ((a % n) + n) % n

function next<T>(curr: T, obj: T[]) {
	let i = obj.indexOf(curr);
	i++;
	i = mod(i, obj.length);
	return obj[i];
}
export { ready, mod, next };
