import * as React from "react";
import deck, { Player, Card, IDisplayable, Category } from "../deck";
import Engine, { GuessFlags } from "../engine";
import { mod, next } from "../util";

enum GuesserState {
	Start, Guess, Accuse,
}

export default class Guesser extends React.Component<{ engine: Engine }, {
	guesser: Player, guess: Card[], disprove?: Player | boolean, state: GuesserState
}> {

	constructor(props: Readonly<{ engine: Engine; }>) {
		super(props);
		const engine = this.props.engine;
		let guess = deck.categories.map(value => value.cards[0]);
		this.state = { guesser: engine.players[0], guess: guess, state: GuesserState.Start }
	}

	changeGuesser = (g: Player) => this.setState({ guesser: g });
	changeGuess = (cat: Category) => (value: Card) => this.setState((oldState) => {
		let g = oldState.guess.slice();
		g[cat.id] = value;
		return { guess: g };
	})
	changeDisprove = (g: Player | boolean) => this.setState({ disprove: g });
	changeDisproveRaw = (ev: React.ChangeEvent<HTMLInputElement>) =>
		this.changeDisprove(ev.target.checked);

	doGuess = () => this.setState({
		state: GuesserState.Guess,
		disprove: next(this.state.guesser, this.props.engine.players)
	})
	doAccuse = () => this.setState({ state: GuesserState.Accuse, disprove: true })
	doBack = () => this.setState({ state: GuesserState.Start });
	doSubmit = () => {
		if (this.state.state == GuesserState.Guess) {
			if (this.state.disprove == this.state.guesser || !(this.state.disprove instanceof Player))
				this.props.engine.makeGuess(this.state.guesser, this.state.guess, GuessFlags.None);
			else
				this.props.engine.makeGuess(this.state.guesser, this.state.guess,
					GuessFlags.Disproved, this.state.disprove);
		} else {
			if (this.state.disprove)
				this.props.engine.makeGuess(this.state.guesser, this.state.guess,
					GuessFlags.Disproved | GuessFlags.Accuse);
			else
				this.props.engine.makeGuess(this.state.guesser, this.state.guess, GuessFlags.Accuse);
		}
		this.setState((oldState) => ({
			guesser: next(oldState.guesser, this.props.engine.players),
			state: GuesserState.Start
		}));
	};

	render() {
		if (this.state.state == GuesserState.Start) {
			let cat = deck.categories.map(value => {
				return <Select key={value.id} name={value.name} options={value.cards}
					value={this.state.guess[value.id]} onChange={this.changeGuess(value)} />
			})
			return <div>
				<Select name="Guesser" options={this.props.engine.players}
					value={this.state.guesser} onChange={this.changeGuesser} />
				{cat}
				<button onClick={this.doGuess}>Guess</button>
				<button onClick={this.doAccuse}>Accuse</button>
			</div>
		}
		let options;
		if (this.state.state == GuesserState.Guess) {
			if (this.state.disprove instanceof Player)
				options = <Select name="Disprover" options={this.props.engine.players}
					value={this.state.disprove} onChange={this.changeDisprove} />
			else
				console.error("Disprove was not set to a player");
		}
		if (this.state.state == GuesserState.Accuse) {
			if (typeof this.state.disprove == "boolean")
				options = <input type="checkbox" checked={this.state.disprove}
					onChange={this.changeDisproveRaw} />
			else
				console.error("Disprove was not set to a boolean");
		}
		return <div>{options}
			<button onClick={this.doBack}>Back</button>
			<button onClick={this.doSubmit}>Submit</button></div>
	}
}

class Select<T extends IDisplayable> extends React.Component<{
	name?: string, options: T[],
	value: T, onChange: (value: T) => any
}> {
	change = (event: React.ChangeEvent<HTMLSelectElement>) => {
		let value = this.props.options.find(value => value.id == Number(event.target.value));
		if (value)
			this.props.onChange(value);
		else
			console.error("Selected element not in list..... Wat?");
	}

	render() {
		let options = this.props.options.map((value, i) =>
			<option value={value.id} key={value.id}>{value.name}</option>);
		return <div>{this.props.name || ""}
			<select onChange={this.change} value={this.props.value.id}>{options}</select></div>
	}
}
